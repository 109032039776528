import React from "react"
import "../styles/all.scss"

const TournamentCard = ({
  name = "",
  tournamentDate = "",
  registrationDeadline = "",
  links = [],
}) => {
  return (
    <div className="column is-12-mobile is-6 tournament-card">
      <div className="box">
        <article className="media">
          <div className="media-content">
            <div className="content has-text-centered">
              <div className="tournament-header is-size-4">
                <strong>{name}</strong>
              </div>
              <div className="is-size-3 has-margin-top-15">
                {tournamentDate}
              </div>
              <div
                className="has-margin-bottom-25"
                style={{ opacity: registrationDeadline ? 1 : 0 }}
              >
                Registration Deadline: {registrationDeadline}
              </div>
            </div>
          </div>
        </article>
        <footer className="card-footer has-text-centered-tablet-only">
          {links.map(link => (
            <a
              className="card-footer-item has-text-centered"
              href={link.path}
              target="_blank"
              rel="noopener noreferrer"
              key={link.path}
            >
              {link.actionName}
            </a>
          ))}
        </footer>
      </div>
    </div>
  )
}

export default TournamentCard
