import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import "../styles/all.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TournamentCard from "../components/tournament-card"
import HeroBar from "../components/hero-bar"

const tournaments = [
  {/*
    name: "New Year’s Frenzy",
    tournamentDate: "Jan 7-9",
    registrationDeadline: "",
    links: [
      {
        path: "/docs/21-22-new-years-frenzy.docx",
        actionName: "info",
      },
      {
        path:
          "https://tourneymachine.com/Public/Results/Tournament.aspx?IDTournament=h2021071521025244453ec04a9707347",
        actionName: "register",
      },
    ],
  },
  {
    name: "St John's Basketball Tournament",
    tournamentDate: "Jan 7-9 (3rd/4th), Jan 14-15(5th/6th)",
    registrationDeadline: "",
    links: [
      {
        path: "/docs/21-22-st-johns.jpg",
        actionName: "info",
      },
    ],
  },
  {
    name: "Bennington Badger Shootout",
    tournamentDate: "Jan 14-16",
    registrationDeadline: "",
    links: [
      {
        path: "/docs/21-22-badger-shootout.png",
        actionName: "info",
      },
      {
        path: "http://tourneymachine.com/E95263",
        actionName: "register",
      },
    ],
  },
  {
    name: "Winter Hoops Challenge Tournament",
    tournamentDate: "Jan 21-23",
    registrationDeadline: "Jan 7",
    links: [
      {
        path: "/docs/21-22-winter-hoops-challenge.jpg",
        actionName: "info",
      },
      {
        path:
          "https://tourneymachine.com/Register/h201411200041034630c83c09bff8740/h20210905212127545b93a2da5066349",
        actionName: "register",
      },
    ],
  },
  {
    name: "Monarch Classic - David City",
    tournamentDate: "Jan 28 or 29",
    registrationDeadline: "",
    links: [
      {
        path: "/docs/21-22-monarch.pdf",
        actionName: "info",
      },
    ],
  },
  {
    name: "Elkhorn Attack 'January Jam' Boys Tournament",
    tournamentDate: "Jan 28-30",
    registrationDeadline: "Jan 7",
    links: [
      {
        path: "/docs/21-22-elkhorn-january-jam.jpg",
        actionName: "info",
      },
      {
        path:
          "https://tourneymachine.com/Register/h201411200041034630c83c09bff8740/h20210905230940996c46eb3c2eefe48",
        actionName: "register",
      },
    ],
  },
  {
    name: "Battle of the Ballers Tournament",
    tournamentDate: "Feb 11-13",
    registrationDeadline: "Jan 25",
    links: [
      {
        path: "/docs/21-22-battle-of-the-ballers.jpg",
        actionName: "info",
      },
      {
        path:
          "https://tourneymachine.com/Register/h201411200041034630c83c09bff8740/h2021090523143010581330fbaa5e44e",
        actionName: "register",
      },
    ],
  },
  {
    name: "Kings & Queens of the Court",
    tournamentDate: "Feb 11-13",
    registrationDeadline: "",
    links: [
      {
        path: "/docs/21-22-king-of-court.jpg",
        actionName: "info",
      },
      {
        path:
          "https://tourneymachine.com/Public/Results/Tournament.aspx?IDTournament=h202107152116509955bc8332db69b44",
        actionName: "register",
      },
    ],
  },
  {
    name: "Nothin' But Net Tournament",
    tournamentDate: "Feb 19-21",
    registrationDeadline: "Jan 31",
    links: [
      {
        path: "/docs/21-22-nothin-but-net.jpg",
        actionName: "info",
      },
      {
        path:
          "https://tourneymachine.com/Register/h201411200041034630c83c09bff8740/h202109052320320961532d78af1d44f",
        actionName: "register",
      },
    ],
  },
  {
    name: "February Finale Boys Tournament",
    tournamentDate: "Feb 25-27",
    registrationDeadline: "Feb 11",
    links: [
      {
        path: "/docs/21-22-february-finale-boys.jpg",
        actionName: "info",
      },
      {
        path:
          "https://tourneymachine.com/Register/h201411200041034630c83c09bff8740/h20210905232631209b8ba0dc5ba794b",
        actionName: "register",
      },
    ],
  },
  {
    name: "Midwest Regional Hoops Championships",
    tournamentDate: "Mar 4-6",
    registrationDeadline: "Feb 18",
    links: [
      {
        path: "/docs/21-22-midwest-regional-championships.jpg",
        actionName: "info",
      },
      {
        path:
          "https://tourneymachine.com/Register/h201411200041034630c83c09bff8740/h2021090523312042715365839aa9c49",
        actionName: "register",
      },
    ],
  },
  {
    name: "The Throwdown in “B” Town (Bellevue)",
    tournamentDate: "Mar 4-6",
    registrationDeadline: "",
    links: [
      {
        path: "/docs/21-22-throw-down-b-town.docx",
        actionName: "info",
      },
      {
        path:
          "https://tourneymachine.com/Public/Results/Tournament.aspx?IDTournament=h20210715212736983477639d4428d4d",
        actionName: "register",
      },
    ],
  },
    */}
]

const CoachPage = () => {
  const data = useStaticQuery(graphql`
    query CoachPageQuery {
      clipboard: file(relativePath: { eq: "clipboards.png" }) {
        childImageSharp {
          fixed(height: 80) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      trophy: file(relativePath: { eq: "trophy.png" }) {
        childImageSharp {
          fixed(height: 80) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Coaches" />
      <HeroBar subtitle="Coach's Corner" />
      <div className="has-background-white-ter has-margin-top-15">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="content">
              <div className="has-margin-top-15 has-margin-15-mobile">
                <div className="tile">
                  <h1 className="title has-padding-bottom-5 why-cav">
                    Coaches
                  </h1>
                </div>
                <div className="tile">
                  <h3 className="subtitle">
                    As Cavalry Coaches, you have the special privilege of
                    sculpting Future Cavaliers.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="columns is-mobile">
        <div className="column is-12 section-icon">
          <Img
            fixed={data.clipboard.childImageSharp.fixed}
            alt="Cavalry Basketball"
          />
        </div>
      </div>
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <div className="content">
            <div className="column is-12">
              {/*<a*/}
              {/*  href="https://cavalrybasketball.skedda.com/booking"*/}
              {/*  target="_blank"*/}
              {/*  rel="noopener noreferrer"*/}
              {/*>*/}
              {/*  Reserve your gym space*/}
              {/*</a>*/}
              <p className="is-size-4">
                As part of the implementation of the CYAO Board,{" "}
                <strong>ANY</strong> adult that intends to and will be working
                with any student or athlete INDEPENDENTLY{" "}
                <strong>is required</strong> to complete a series of courses for
                adequate preparation for supervision of SCS students.
              </p>
              <p className="is-size-4">
                These courses include the Diocese of Lincoln{" "}
                <a
                  href="https://lincoln.cmgconnect.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Safe Environment Training
                </a>{" "}
                and the{" "}
                <a
                  href="https://nfhslearn.com/courses/61037/concussion-in-sports"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  NSAA Concussion Training
                </a>
                . Both courses are FREE.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="columns is-mobile">
        <div className="column is-12 section-icon">
          <Img
            fixed={data.trophy.childImageSharp.fixed}
            alt="Cavalry Basketball"
          />
        </div>
      </div>
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <div className="columns">
            <div className="column is-12 section-header">
              <h1 className="title is-1">Tournaments</h1>
            </div>
          </div>
          <div className="columns is-multiline tournament-cards">
            {tournaments.map(tournament => {
              return (
                <TournamentCard
                  key={tournament.name}
                  name={tournament.name}
                  tournamentDate={tournament.tournamentDate}
                  registrationDeadline={tournament.registrationDeadline}
                  links={tournament.links}
                />
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CoachPage
